// @ts-nocheck

import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';

import InfoItem from './item/item';
import Fees from './item/fees';
import AdditionalInfo from './additionalInfo/additionalInfo';
import { getEventDateRange, getEventStartLocalDateTime } from '@/helpers/moment/moment.utils';
import { formatMoney } from '@/helpers/moneyFormatter/moneyFormatter';
import { getSingleFee } from '@/helpers/fee/feeHelper';
import T from '@/helpers/i18n';

import './info.scss';
import i18next from 'i18next';
import { getFormattedVenueAddress } from '@/models/eventModel';
import { EnvelopeModel, resolveReferenceId } from '@/models/envelopeModel';
import { getBestDescription } from '@/models/ticketModel.ts';

type Props = {
  envelope: EnvelopeModel;
  active: boolean;
} & T;

const Info = ({ t, envelope, active }: Props) => {
  const shouldDisplayCompleteFeeBreakdown = envelope.shouldDisplayCompleteFeeBreakdown;

  const event = envelope.event;
  const ticket = envelope.tickets.find((t) => t.id === active);
  const currency = ticket.currencyCode?.toUpperCase()
  const locale = i18next.language;
  const singleFee = getSingleFee(envelope, ticket);
  const subtotal = shouldDisplayCompleteFeeBreakdown ? +ticket.price - ticket.discount : +ticket.price - ticket.discount + singleFee + +ticket.tax;
  const total = shouldDisplayCompleteFeeBreakdown ? singleFee + subtotal + +ticket.tax : subtotal;
  const referenceId = resolveReferenceId(envelope);

  const getBarcodeOrNoBarcodeMessage = (): string => {
    const { isSecureEntry, barcode } = ticket;

    if (barcode) {
      return barcode;
    }

    return isSecureEntry ? t('WALLET.TICKETS.BARCODE_AVAILABLE_LATER') : t('WALLET.TICKETS.NO_BARCODE');
  };

  const getTermsAndConditions = (): string => {
    const { termsAndConditions } = ticket;

    if (termsAndConditions) {
      return termsAndConditions;
    }
    return t('WALLET.INFO.TERMS_AND_CONDITIONS_ERROR');
  };

  return (
    <div className="wallet-info">
      {!!referenceId && (
        <InfoItem title={'WALLET.INFO.ORDER_ID'} data={referenceId}/>
      )}
      <InfoItem title={'WALLET.INFO.EVENT_ID'} data={event.systemId || event.discoveryId}/>
      {!!event.venue.address && (
        <InfoItem title={'WALLET.INFO.VENUE_ADDRESS'} data={getFormattedVenueAddress(event.venue)}/>
      )}
      {!!event.startLocalDate && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={getEventStartLocalDateTime(event.startLocalDate, event.startLocalTime)}/>
      )}
      {!!event.dateRange && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={getEventDateRange(event.dateRange)}/>
      )}
      {!event.startLocalDate && !event.dateRange && (
        <InfoItem title={'WALLET.INFO.DATE_TIME'} data={t('WALLET.INFO.TBA_SHORTEN')}/>
      )}
      <InfoItem title={'WALLET.INFO.BARCODE'} data={getBarcodeOrNoBarcodeMessage()}/>
      <InfoItem title={'WALLET.INFO.TICKET_TYPE'} data={getBestDescription(ticket) || t('WALLET.TICKETS.DESCRIPTION')}/>
      {ticket.price !== undefined && ticket.price !== null &&
        <InfoItem
          title={'WALLET.INFO.CHARGES'}
          data={[
            `${t('WALLET.INFO.CHARGES_TICKET_VALUE')}: ${formatMoney(locale, currency, subtotal)}`,
            shouldDisplayCompleteFeeBreakdown ?
              <Fees title={t('WALLET.INFO.CHARGES_TICKET_FEE')} feeBreakdown={ticket.fees} singleFee={singleFee} locale={locale} currency={currency}/> : null,
            (Number(ticket.tax) === 0 || !shouldDisplayCompleteFeeBreakdown) ? null : `${t('WALLET.INFO.CHARGES_TICKET_TAX')}: ${formatMoney(locale, currency, +ticket.tax)}`,
            `${t('WALLET.INFO.CHARGES_TICKET_TOTAL')}: ${formatMoney(locale, currency, total)}`,
          ]}
        />
      }
      <AdditionalInfo title={'WALLET.INFO.ADDITIONAL_INFO'} ticket={ticket} countryCode={event.venue.countryCode}/>
      <InfoItem
        title={'WALLET.INFO.TERMS_AND_CONDITIONS'}
        data={getTermsAndConditions()}
      />
    </div>
  );
};

export default withTranslation('translation')(memo(Info));

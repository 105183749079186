export type TicketModel = {
  id: number;
  voidedAt: object;
  bgColorHex: string;
  fontColorHex: string;
  description: string;
  longDescription: string;
  isGa: boolean;
  additionalInfos: TicketAdditionalInfo[];
  exitPortal: string;
  shouldNotShowBarcode: boolean;
  showGARef: boolean;
  section: string;
  row: string;
  seat: string;
  barcode: string;
  isSecureEntry: boolean;
  rotatingEntryToken: boolean;
  renderType: string;
  fee: number;
  price: number;
  currencyCode: string;
}

export type TicketAdditionalInfo = {
  key: string;
  type: string;
  value: string;
}

export function getBestDescription(ticket: TicketModel): string {
  return ticket.longDescription || ticket.description;
}
